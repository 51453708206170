<template>

  <div>
    <div style="height: 0; opacity: 0; overflow: hidden; min-width:790px; width:790px; max-width:unset;">
      <b-card
        id="pdfGenerateDiv"
        ref="pdfGenerateDivRef"
        no-body
        class="invoice-preview-card"
      >
        <div
          v-for="pg in pdfTotalPage"
          :key="pg"
          class="card__inner"
          style="height: 1080px;"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">

            <div class="d-flex justify-content-between flex-md-row invoice-spacing mt-0">
              <div>
                <div class="logo-wrapper">
                  <img
                    style="width:130px;"
                    src="/nirvana-memorial-garden-logo.png"
                    alt="Logo"
                  >
                </div>
              </div>
              <div class="mt-md-0 mt-2 text-right">
                <h4
                  class="invoice-title"
                  style="font-size: 18px;line-height: 21px;color: #104D9D; margin:0;"
                >
                  {{ companyName }}
                </h4>
                <div class="invoice-date-wrapper">
                  <p
                    class="invoice-date"
                    style="font-weight: normal !important;font-size: 11px;line-height: 16px; margin:0;"
                  >
                    <span class="semi-bold">Prepared By </span> {{ user.name }}, {{ user.position }}, {{ user.department.length ? user.department[0].name : '' }}
                  </p>
                </div>
                <div class="invoice-date-wrapper">
                  <p
                    class="invoice-date"
                    style="font-weight: normal !important;font-size: 11px;line-height: 16px; margin:0;"
                  >
                    <span class="semi-bold">Created On:</span> {{ dateFormatWithTime(new Date()) }}
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr
            class="invoice-spacing"
            style="margin:15px 0!important"
          >

          <!-- Invoice Client & Payment Details -->
          <b-card-body
            v-if="pg == 1"
            class="invoice-padding pt-0 main__body"
          >
            <b-row class="invoice-spacing">
              <b-col
                cols="md-12"
                class="p-0"
              >
                <h1
                  class="text-bold-black"
                  style="font-size:14px; line-height:18px; font-weight:900; color:#000000; margin:0 0 15px"
                >
                  New Vendor Registration Form
                </h1>
                <h6
                  style="font-size:14px; line-height:17px; color:#000000; margin:0 0 8px; font-weight:500;"
                >
                  Section 1: Company General Information
                </h6>
                <b-table-simple
                  borderless
                  responsive
                >
                  <b-tbody>
                    <b-tr>
                      <b-th width="230px">
                        Registration No.
                      </b-th>
                      <b-td>{{ companyRegistration }}</b-td>
                      <b-th width="230px">
                        Tel
                      </b-th>
                      <b-td>{{ companyContact }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th width="230px">
                        GST Registration No.
                      </b-th>
                      <b-td>{{ companyGSTRegistration }}</b-td>
                      <b-th width="230px">
                        Fax
                      </b-th>
                      <b-td>{{ companyFax }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th width="230px">
                        NRIC No. (For Individual)
                      </b-th>
                      <b-td>{{ individualNRIC }}</b-td>
                      <b-th width="230px">
                        Registered Address
                      </b-th>
                      <b-td>{{ companyAddress }}</b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
            </b-row>
            <hr
              class="invoice-spacing"
              style="margin:15px 0!important"
            >
            <b-row>
              <b-col
                cols="md-12"
                xl="12"
              >
                <h6
                  style="font-size:14px; line-height:17px; color:#000000; margin:0 0 8px; font-weight:500;"
                >
                  Section 2: Sales Person Information
                </h6>
                <b-table-simple
                  borderless
                  responsive
                >
                  <b-tbody>
                    <b-tr>
                      <b-th width="230px">
                        Name
                      </b-th>
                      <b-td>{{ salesPersonName }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th width="230px">
                        Contact No.
                      </b-th>
                      <b-td>{{ salesPersonContact }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th width="230px">
                        Email Address
                      </b-th>
                      <b-td>{{ salesPersonEmail }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th width="230px">
                        Email Address 2
                      </b-th>
                      <b-td>{{ salesPersonEmail2 }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th width="230px">
                        Any Related Family Parties Working In NV Group?
                      </b-th>
                      <b-td>
                        <span class="text-capitalize">{{ relativeInNirvana }}</span>
                        <br>
                        <span v-if="relativeInNirvana == 'yes'">
                          {{ relativeDetail }}
                        </span>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
            </b-row>
            <hr
              class="invoice-spacing"
              style="margin:15px 0!important"
            >
            <b-row>
              <b-col
                cols="md-12"
                xl="12"
              >
                <h6 style="font-size:14px; line-height:17px; color:#000000; margin:0 0 8px; font-weight:500;">
                  Section 3: Product / Service Provided and Payment Terms
                </h6>
                <b-table-simple
                  borderless
                  responsive
                >
                  <b-tbody>
                    <b-tr>
                      <b-th width="230px">
                        Type of Product/Service Provided
                      </b-th>
                      <b-td>{{ providedServiceType }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th width="230px">
                        Payment Terms
                      </b-th>
                      <b-td>
                        <span class="text-capitalize">{{ paymentTerms }}</span>
                        <br>
                        <span v-if="paymentTerms == 'Others'">
                          {{ paymentTermsDescription }}
                        </span>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
            </b-row>

            <hr
              class="invoice-spacing"
              style="margin:15px 0!important"
            >
            <b-row>
              <b-col
                cols="md-12"
                xl="12"
              >
                <h6 style="font-size:14px; line-height:17px; color:#000000; margin:0 0 8px; font-weight:500;">
                  Section 4: Banking Information
                </h6>
                <b-table-simple
                  borderless
                  responsive
                >
                  <b-tbody>
                    <b-tr>
                      <b-th width="230px">
                        Account Payee Name
                      </b-th>
                      <b-td>{{ accountPayeeName }}</b-td>
                      <b-th width="230px">
                        Bank Account No.
                      </b-th>
                      <b-td>{{ bankAccountNo }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th width="230px">
                        Bank Name
                      </b-th>
                      <b-td>{{ bankName }}</b-td>
                      <b-th width="230px">
                        Bank Address
                      </b-th>
                      <b-td>{{ bankAddress }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th width="230px">
                        Bank Branch
                      </b-th>
                      <b-td>{{ bankBranch }}</b-td>
                      <b-th width="230px">
                        Account Currency
                      </b-th>
                      <b-td>{{ accountCurrency }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th width="230px">
                        Swift Code (For Overseas)
                      </b-th>
                      <b-td>{{ swiftCode }}</b-td>
                      <b-th width="230px">
                        Email Address
                      </b-th>
                      <b-td>{{ bankEmail }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>
                        Paynow Details
                      </b-th>
                      <b-td>{{ paynowNo }}</b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
            </b-row>
            <hr
              class="invoice-spacing"
              style="margin:15px 0!important"
            >
            <b-row>
              <b-col
                cols="md-12"
                xl="12"
              >
                <h6 style="font-size:14px; line-height:17px; color:#000000; margin:0 0 8px; font-weight:500;">
                  Section 5: Certification Obtained
                </h6>
              </b-col>
              <b-col
                v-for="(certificate, key) in certificates"
                :key="key"
                class="content-header-left"
                cols="12"
                md="4"
                style="margin-bottom:7px"
              >
                <p
                  style="font-size:11px; line-height:16px; margin:0; color:#333333;"
                >
                  <span v-if="certificate == 'Others'">
                    {{ otherCertificates }}
                  </span>
                  <span v-else>
                    {{ certificate }}
                  </span>
                </p>
              </b-col>
            </b-row>
            <hr
              class="invoice-spacing"
              style="margin:8px 0 7px!important"
            >
            <p
              style="font-size:10px; line-height:14px; margin:0 0 15px; color:#000000;"
            >
              Section 1–5 to be completed by vendor and kindly attach with necessary supporting documents for submission.
            </p>
            <p
              style="font-size:10px; line-height:14px; margin:0 0 0px; color:#000000;"
            >
              I hereby affirm that all information supplied is true and accurate to the best of my knowledge, and I understand that this information will be taken into consideration during the vendor evaluation. In the condition of any changes impacting the information provided above, a notice shall be provided to the company within 14 days of the said change.
            </p>
          </b-card-body>
          <div
            v-if="pg == pdfTotalPage"
            style="position: relative; bottom: 0; width: 100%;"
          >
            <div :style="{ height: gapHeight+'px' }" />
            <!-- Spacer -->
            <hr class="invoice-spacing">

            <!-- Invoice Description: Total -->
            <b-card-body class="invoice-padding pb-0 footer__area">
              <b-row>

                <b-col
                  v-for="(signatory, index) of signatoryAssigns"
                  :key="index"
                  cols="3"
                  md="3"
                  class="mt-md-1 mt-1"
                  order="2"
                  order-md="1"
                >
                  <b-card-text class="mb-0">
                    <span class="font-weight-bold">{{ loas[index].code }}</span>
                    <div class="sign-box">
                      <p>Pending Signature</p>
                    </div>
                    <p class="ml-75 semi-bold">
                      {{ resolveSignatoryName(signatory) }}
                    </p>
                    <p class="ml-75">
                      {{ resolveSignatoryDepartment(signatory) }}
                    </p>
                  </b-card-text>
                </b-col>
              </b-row>
            </b-card-body>
          </div>
        </div>
      </b-card>
      <b-card
        no-body
        class="invoice-preview-card"
      >
        <div
          class="card__inner"
          style="height: 1120px;"
        >
          <!-- Header -->
          <b-card-body
            id="main-header"
            class="invoice-padding pb-0"
          >
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <div class="logo-wrapper">
                <img
                  style="width:130px;"
                  src="/nirvana-memorial-garden-logo.png"
                  alt="Logo"
                >
              </div>
              <div class="mt-md-0 mt-2 text-right">
                <h4
                  class="invoice-title"
                  style="font-size: 18px;line-height: 21px;color: #104D9D; margin:0;"
                >
                  {{ companyName }}
                </h4>
                <div class="invoice-date-wrapper">
                  <p
                    class="invoice-date"
                    style="font-weight: normal !important;font-size: 11px;line-height: 16px; margin:0;"
                  >
                    <span class="semi-bold">Prepared By </span> {{ user.name }}, {{ user.position }}, {{ user.department.length ? user.department[0].name : '' }}
                  </p>
                </div>
                <div class="invoice-date-wrapper">
                  <p
                    class="invoice-date"
                    style="font-weight: normal !important;font-size: 11px;line-height: 16px; margin:0;"
                  >
                    <span class="semi-bold">Created On:</span> {{ dateFormatWithTime(new Date()) }}
                  </p>
                </div>
              </div>
            </div>
            <!-- Spacer -->
            <hr
              class="invoice-spacing"
              style="margin-top:25px!important"
            >
          </b-card-body>

          <!-- Invoice Client & Payment Details -->
          <b-card-body
            id="content-div"
            class="invoice-padding pt-2 main__body po__create"
          >
            <b-row>
              <b-col cols="md-12">
                <h1 class="text-bold-black pl-1">
                  New Vendor Registration Form
                </h1>
              </b-col>
            </b-row>
            <b-row id="section1">
              <b-col
                cols="md-12"
                xl="12"
              >
                <h6 class="ml-2 mt-2">
                  Section 1: Company General Information
                </h6>
                <b-table-simple
                  borderless
                  responsive
                >
                  <b-tbody>
                    <b-tr>
                      <b-th>
                        Registration No.
                      </b-th>
                      <b-td>{{ companyRegistration }}</b-td>
                      <b-th>
                        Tel
                      </b-th>
                      <b-td>{{ companyContact }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>
                        GST Registration No.
                      </b-th>
                      <b-td>{{ companyGSTRegistration }}</b-td>
                      <b-th>
                        Fax
                      </b-th>
                      <b-td>{{ companyFax }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>
                        NRIC No. (For Individual)
                      </b-th>
                      <b-td>{{ individualNRIC }}</b-td>
                      <b-th>
                        Registered Address
                      </b-th>
                      <b-td>{{ companyAddress }}</b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
            </b-row>
            <hr class="invoice-spacing">
            <b-row id="section2">
              <b-col
                cols="md-12"
                xl="12"
              >
                <h6 class="ml-2 mt-2">
                  Section 2: Sales Person Information
                </h6>
                <b-table-simple
                  borderless
                  responsive
                >
                  <b-tbody>
                    <b-tr>
                      <b-th>
                        Name
                      </b-th>
                      <b-td>{{ salesPersonName }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>
                        Contact No.
                      </b-th>
                      <b-td>{{ salesPersonContact }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>
                        Email Address
                      </b-th>
                      <b-td>{{ salesPersonEmail }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>
                        Email Address 2
                      </b-th>
                      <b-td>{{ salesPersonEmail2 }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>
                        Any Related Family Parties Working In NV Group?
                      </b-th>
                      <b-td>
                        <span class="text-capitalize">{{ relativeInNirvana }}</span>
                        <br>
                        <span v-if="relativeInNirvana == 'yes'">
                          {{ relativeDetail }}
                        </span>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
            </b-row>
            <hr class="invoice-spacing">
            <b-row id="section3">
              <b-col
                cols="md-12"
                xl="12"
              >
                <h6 class="ml-2 mt-2">
                  Section 3: Product / Service Provided and Payment Terms
                </h6>
                <b-table-simple
                  borderless
                  responsive
                >
                  <b-tbody>
                    <b-tr>
                      <b-th>
                        Type of Product/Service Provided
                      </b-th>
                      <b-td>{{ providedServiceType }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>
                        Payment Terms
                      </b-th>
                      <b-td>
                        <span class="text-capitalize">{{ paymentTerms }}</span>
                        <br>
                        <span v-if="paymentTerms == 'Others'">
                          {{ paymentTermsDescription }}
                        </span>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
            </b-row>

            <hr class="invoice-spacing">
            <b-row id="section4">
              <b-col
                cols="md-12"
                xl="12"
              >
                <h6 class="ml-2 mt-2">
                  Section 4: Banking Information
                </h6>
                <b-table-simple
                  borderless
                  responsive
                >
                  <b-tbody>
                    <b-tr>
                      <b-th>
                        Account Payee Name
                      </b-th>
                      <b-td>{{ accountPayeeName }}</b-td>
                      <b-th>
                        Bank Account No.
                      </b-th>
                      <b-td>{{ bankAccountNo }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>
                        Bank Name
                      </b-th>
                      <b-td>{{ bankName }}</b-td>
                      <b-th>
                        Bank Address
                      </b-th>
                      <b-td>{{ bankAddress }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>
                        Bank Branch
                      </b-th>
                      <b-td>{{ bankBranch }}</b-td>
                      <b-th>
                        Account Currency
                      </b-th>
                      <b-td>{{ accountCurrency }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>
                        Swift Code (For Overseas)
                      </b-th>
                      <b-td>{{ swiftCode }}</b-td>
                      <b-th>
                        Email Address
                      </b-th>
                      <b-td>{{ bankEmail }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>
                        Paynow Details
                      </b-th>
                      <b-td>{{ paynowNo }}</b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
            </b-row>
            <hr class="invoice-spacing">
            <b-row id="section5">
              <b-col
                cols="md-12"
                xl="12"
              >
                <h6 class="ml-2 mt-2">
                  Section 5: Certification Obtained
                </h6>
              </b-col>
              <b-col
                v-for="(certificate, key) in certificates"
                :key="key"
                class="content-header-left mb-2"
                cols="12"
                md="4"
              >
                <div
                  class="attachment-item"
                >
                  <span v-if="certificate == 'Others'">
                    {{ otherCertificates }}
                  </span>
                  <span v-else>
                    {{ certificate }}
                  </span>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Invoice Description: Total -->
          <b-card-body
            id="footer-div"
            class="invoice-padding pb-0 footer__area"
          >
            <!-- Spacer -->
            <hr class="invoice-spacing">
            <b-row>

              <!-- Col: Sales Persion -->
              <b-col
                v-for="(signatory, index) of signatoryAssigns"
                :key="index"
                cols="3"
                md="3"
                class="mt-md-1 mt-1"
                order="2"
                order-md="1"
              >
                <b-card-text class="mb-0">
                  <span class="font-weight-bold">{{ loas[index].code }}</span>
                  <div class="sign-box">
                    <p>Pending Signature</p>
                  </div>
                  <p class="ml-75 semi-bold">
                    {{ resolveSignatoryName(signatory) }}
                  </p>
                  <p class="ml-75">
                    {{ resolveSignatoryDepartment(signatory) }}
                  </p>
                </b-card-text>
              </b-col>
            </b-row>
          </b-card-body>
        </div>
      </b-card>
    </div>

    <div v-show="vendorPreview">
      <b-row
        class="content-header"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          cols="12"
          md="12"
        >
          <b-row class="breadcrumbs-top">
            <b-col
              class="content-header-left mb-2"
              cols="12"
              md="12"
            >
              <h1 class="custom-header-title">
                Preview {{ vendor.stringID }} - {{ vendor.companyName }}
              </h1>
              <p class="log_info">
                Created by {{ vendor.createdBy ? vendor.createdBy.name : '' }} on {{ dateFormatWithTime(vendor.createdAt) }}<br>Last updated on {{ dateFormatWithTime(vendor.updatedAt) }}
              </p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <section class="invoice-preview-wrapper">
        <b-row
          class="invoice-preview"
        >

          <!-- Col: Left (Invoice Container) -->
          <b-col
            cols="12"
            xl="9"
            md="8"
          >
            <div>
              <b-card
                class="mb-0"
              >
                <b-row class="invoice-preview">
                  <b-col
                    cols="12"
                    xl="4"
                    md="4"
                    class="text-left"
                  >
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="link"
                      :disabled="currentFileIndex == 1"
                      @click="changeFileIndex(currentFileIndex - 1)"
                    >
                      <feather-icon
                        icon="ChevronLeftIcon"
                        class="pt-25"
                        size="18"
                      />
                      <span class="align-middle">Prev</span>
                    </b-button>
                  </b-col>
                  <b-col
                    cols="12"
                    xl="4"
                    md="4"
                    class="text-center"
                  >
                    <b-dropdown
                      id="dropdown-1"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      split
                    >
                      <template #button-content>
                        <feather-icon
                          icon="FileIcon"
                          size="18"
                          class="align-middle mr-1"
                        />
                        <span class="mr-1">{{ currentFileIndex.toString().padStart(2, "0") }} / {{ attachments ? ((attachments.length + 1).toString().padStart(2, "0")) : '01' }}: {{ selectedFileName }}</span>
                      </template>
                      <b-dropdown-item @click="changeFileIndex(1)">
                        {{ companyName }}
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-for="(file, key) in attachments"
                        :key="key"
                        @click="changeFileIndex(key + 2)"
                      >
                        {{ file ? file.name : '' }}
                      </b-dropdown-item>
                    </b-dropdown>
                    <p
                      v-show="currentFileIndex == 1"
                      class="mt-1"
                    >
                      New Vendor Registration Form
                    </p>
                    <p
                      v-show="currentFileIndex != 1"
                      class="mt-1"
                    >
                      {{ attachments.length ? (attachments[currentFileIndex - 2] ? attachments[currentFileIndex - 2].description : '') : '' }}
                    </p>
                  </b-col>
                  <b-col
                    cols="12"
                    xl="4"
                    md="4"
                    class="text-right"
                  >
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="link"
                      :disabled="currentFileIndex == (attachments ? (attachments.length + 1) : 1)"
                      @click="changeFileIndex(currentFileIndex + 1)"
                    >
                      <span class="align-middle">Next</span>
                      <feather-icon
                        icon="ChevronRightIcon"
                        class="pt-25"
                        size="18"
                      />
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </div>
            <b-card
              no-body
              class="invoice-preview-card"
            >
              <div v-show="showAttachment">
                <iframe
                  v-show="showPDF"
                  id="attachmentpdf"
                  width="100%"
                  height="1145"
                />
                <b-img
                  v-show="!showPDF"
                  :src="imageSrc"
                  fluid-grow
                  alt="Fluid-grow image"
                />
              </div>
              <div v-show="!showAttachment">
                <iframe
                  id="docpdf"
                  width="100%"
                  height="1145"
                />
              </div>
            </b-card>
          </b-col>

          <!-- Right Col: Card -->
          <b-col
            cols="12"
            md="4"
            xl="3"
            class="invoice-actions"
          >
            <b-card>

              <template #header>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="button"
                  variant="flat-primary"
                  class="primary-button-with-background mr-3"
                >
                  <feather-icon
                    icon="GridIcon"
                    class=""
                    size="20"
                  />
                </b-button>
                <h3 class="align-middle mr-auto">
                  <span class="step-class">Approval Required</span>
                  Ready to Submit?
                </h3>
              </template>

              <b-card-text class="mb-0">
                <span>
                  If the information on this New Vendor Form is accurate, e-sign this form and it will be sent to the next assigned signatory ({{ resolveNextSignatoryName() }}) for signing.
                </span>
              </b-card-text>

              <b-form-checkbox
                v-model="secondAgree"
                value="accepted"
                unchecked-value=""
                class="custom-control-primary"
              >
                I confirm that all information is accurate on this form.
              </b-form-checkbox>

              <!-- Button: Send Invoice -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                class="mb-75"
                block
                :disabled="secondAgree == ''"
                @click="finalConfirmation()"
              >
                <feather-icon
                  icon="FeatherIcon"
                  class="mr-50"
                  size="16"
                />
                <span class="align-middle">Sign and Submit Form</span>
              </b-button>

              <!-- Button: DOwnload -->
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="flat-primary"
                class="mb-75"
                block
                @click="enableEdit()"
              >
                Back to Editing
              </b-button>
            </b-card>
          </b-col>
        </b-row>
      </section>
    </div>
    <div v-show="!vendorPreview">
      <b-row
        class="content-header create-edit-form"
      >
        <b-col
          class="content-header-left mb-2"
          cols="12"
          md="12"
        >
          <h1 class="custom-header-title">
            Editing {{ vendor.stringID }} - {{ vendor.companyName }}
          </h1>
          <p class="log_info">
            Created by {{ vendor.createdBy ? vendor.createdBy.name : '' }} on {{ dateFormatWithTime(vendor.createdAt) }}<br>Last updated on {{ dateFormatWithTime(vendor.updatedAt) }}
          </p>
        </b-col>
      </b-row>
      <!-- Table Container Card -->
      <b-form @submit.prevent="submitForm">
        <validation-observer
          ref="vendorCreateForm"
          #default="{invalid}"
        >
          <b-card
            header-tag="header"
          >
            <template #header>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                class="primary-button-with-background mr-3"
              >
                <feather-icon
                  icon="GridIcon"
                  class=""
                  size="20"
                />
              </b-button>
              <h3 class="align-middle mr-auto">
                <small>section 1</small>
                Company General Information
              </h3>
            </template>
            <b-row>
              <b-col cols="md-6">
                <b-form-group
                  label="Company Name*"
                  label-for="h-booking-companyName"
                  label-cols-md="4"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Company Name"
                    vid="companyName"
                    rules="required"
                  >
                    <b-form-input
                      id="h-booking-companyName"
                      v-model="companyName"
                      :state="(errors.length > 0 || companyNameValidation) ? false : null"
                      name="companyName"
                      placeholder="ABC Kitchen Equipment Pte Ltd"
                      @input="companyNameValidation == true ? companyNameValidation = false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="companyNameValidation"
                      class="text-danger"
                    >
                      {{ companyNameError }}
                    </small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Registration No."
                  label-for="h-booking-companyRegistration"
                  label-cols-md="4"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Registration No."
                    vid="companyRegistration"
                    rules=""
                  >
                    <b-form-input
                      id="h-booking-companyRegistration"
                      v-model="companyRegistration"
                      :state="(errors.length > 0 || companyRegistrationValidation) ? false : null"
                      name="companyRegistration"
                      placeholder="201208027C"
                      @input="companyRegistrationValidation == true ? companyRegistrationValidation = false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="companyRegistrationValidation"
                      class="text-danger"
                    >
                      {{ companyRegistrationError }}
                    </small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="GST Registration No."
                  label-for="h-booking-companyGSTRegistration"
                  label-cols-md="4"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="GST Registration No."
                    vid="companyGSTRegistration"
                  >
                    <b-form-input
                      id="h-booking-companyGSTRegistration"
                      v-model="companyGSTRegistration"
                      :state="(errors.length > 0 || companyGSTRegistrationValidation) ? false : null"
                      name="companyGSTRegistration"
                      placeholder="201208027C"
                      @input="companyGSTRegistrationValidation == true ? companyGSTRegistrationValidation = false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="companyGSTRegistrationValidation"
                      class="text-danger"
                    >
                      {{ companyGSTRegistrationError }}
                    </small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="NRIC No. (For Individual Vendor)"
                  label-for="h-booking-individualNRIC"
                  label-cols-md="4"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="NRIC No. (For Individual Vendor)"
                    vid="individualNRIC"
                  >
                    <b-form-input
                      id="h-booking-individualNRIC"
                      v-model="individualNRIC"
                      :state="(errors.length > 0 || individualNRICValidation) ? false : null"
                      name="individualNRIC"
                      placeholder="S7120827C"
                      @input="individualNRICValidation == true ? individualNRICValidation = false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="individualNRICValidation"
                      class="text-danger"
                    >
                      {{ individualNRICError }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="md-6">
                <b-form-group
                  label="Tel*"
                  label-for="h-booking-companyContact"
                  label-cols-md="4"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Tel"
                    vid="companyContact"
                    rules="required"
                  >
                    <b-form-input
                      id="h-booking-companyContact"
                      v-model="companyContact"
                      placeholder="e.g. +65-99998888"
                      :state="(errors.length > 0 || companyContactValidation) ? false : null"
                      name="companyContact"
                      @input="companyContactValidation == true ? companyContactValidation = false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="companyContactValidation"
                      class="text-danger"
                    >
                      {{ companyContactError }}
                    </small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Fax"
                  label-for="h-booking-companyFax"
                  label-cols-md="4"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Fax"
                    vid="companyFax"
                    rules=""
                  >
                    <b-form-input
                      id="h-booking-companyFax"
                      v-model="companyFax"
                      :state="(errors.length > 0 || companyFaxValidation) ? false : null"
                      name="companyFax"
                      placeholder="20120802"
                      @input="companyFaxValidation == true ? companyFaxValidation = false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="companyFaxValidation"
                      class="text-danger"
                    >
                      {{ companyFaxError }}
                    </small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Registered Address*"
                  label-for="h-booking-companyAddress"
                  label-cols-md="4"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Registered Address"
                    vid="companyAddress"
                    rules="required"
                  >
                    <b-form-textarea
                      id="h-booking-companyAddress"
                      v-model="companyAddress"
                      trim
                      placeholder="950 Old Choa Chu Kang Road Singapore 699816"
                      :state="companyAddressValidation == true ? companyAddressValidation = false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="companyAddressValidation"
                      class="text-danger"
                    >
                      {{ companyAddressError }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>

          <b-card
            header-tag="header"
          >
            <template #header>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                class="primary-button-with-background mr-3"
              >
                <feather-icon
                  icon="GridIcon"
                  class=""
                  size="20"
                />
              </b-button>
              <h3 class="align-middle mr-auto">
                <small>section 2</small>
                Sales Person Information
              </h3>
            </template>
            <b-row>
              <b-col cols="md-6">
                <b-form-group
                  label="Name*"
                  label-for="h-booking-salesPersonName"
                  label-cols-md="4"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Name"
                    vid="salesPersonName"
                    rules="required"
                  >
                    <b-form-input
                      id="h-booking-salesPersonName"
                      v-model="salesPersonName"
                      placeholder="First name and last name"
                      :state="(errors.length > 0 || salesPersonNameValidation) ? false : null"
                      name="salesPersonName"
                      @input="salesPersonNameValidation == true ? salesPersonNameValidation = false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="salesPersonNameValidation"
                      class="text-danger"
                    >
                      {{ salesPersonNameError }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="md-6">
                <b-form-group
                  label="Email Address"
                  label-for="h-booking-salesPersonEmail"
                  label-cols-md="4"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Email Address"
                    vid="salesPersonEmail"
                    rules="email"
                  >
                    <b-form-input
                      id="h-booking-salesPersonEmail"
                      v-model="salesPersonEmail"
                      placeholder="email@domain.com"
                      :state="(errors.length > 0 || salesPersonEmailValidation) ? false : null"
                      name="salesPersonEmail"
                      @input="salesPersonEmailValidation == true ? salesPersonEmailValidation = false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="salesPersonEmailValidation"
                      class="text-danger"
                    >
                      {{ salesPersonEmailError }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="md-6">
                <b-form-group
                  label="Contact No.*"
                  label-for="h-booking-salesPersonContact"
                  label-cols-md="4"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Contact No."
                    vid="salesPersonContact"
                    rules="required"
                  >
                    <b-form-input
                      id="h-booking-salesPersonContact"
                      v-model="salesPersonContact"
                      :state="(errors.length > 0 || salesPersonContactValidation) ? false : null"
                      name="salesPersonContact"
                      placeholder="e.g. +65-99998888"
                      @input="salesPersonContactValidation == true ? salesPersonContactValidation = false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="salesPersonContactValidation"
                      class="text-danger"
                    >
                      {{ salesPersonContactError }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="md-6">
                <b-form-group
                  label="Email Address 2"
                  label-for="h-booking-salesPersonEmail2"
                  label-cols-md="4"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Email Address 2"
                    vid="salesPersonEmail2"
                    rules="email"
                  >
                    <b-form-input
                      id="h-booking-salesPersonEmail2"
                      v-model="salesPersonEmail2"
                      placeholder="email@domain.com"
                      :state="(errors.length > 0 || salesPersonEmail2Validation) ? false : null"
                      name="salesPersonEmail2"
                      @input="salesPersonEmail2Validation == true ? salesPersonEmail2Validation = false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="salesPersonEmail2Validation"
                      class="text-danger"
                    >
                      {{ salesPersonEmail2Error }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="md-12">
                <hr class="dividerHR">
              </b-col>
              <b-col
                cols="12"
              >
                <b-form-group
                  label="Any Related Family Parties Working In NV Group?"
                  label-for="relativeInNirvana"
                  label-cols-md="8"
                  class="auto_width_label"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Any Related Family Parties Working In NV Group?"
                    rules=""
                  >
                    <b-form-radio-group
                      id="relativeInNirvana"
                      v-model="relativeInNirvana"
                      buttons
                      button-variant="outline-primary"
                      class="spaceBetweenRadio"
                    >
                      <b-form-radio
                        v-for="(option, index) in nvGroupOptionOptions"
                        :key="index"
                        :value="option.value"
                        class="mb-50"
                        :state="(errors.length > 0) ? false : null"
                      >
                        <feather-icon
                          v-if="relativeInNirvana === option.value"
                          icon="CheckIcon"
                          class="mr-50"
                          size="16"
                        />
                        {{ option.text }}
                      </b-form-radio>
                    </b-form-radio-group>
                    <br>

                    <small
                      v-if="relativeInNirvanaValidation"
                      class="text-danger"
                    >
                      {{ relativeInNirvanaError }}
                    </small>
                  </validation-provider>
                  <validation-provider
                    v-if="relativeInNirvana == 'yes'"
                    #default="{ errors }"
                    vid="relativeDetail"
                    rules=""
                  >
                    <b-form-input
                      id="h-booking-relativeDetail"
                      v-model="relativeDetail"
                      placeholder="Please declare name and relationship"
                      :state="(errors.length > 0 || relativeDetailValidation) ? false : null"
                      name="relativeDetail"
                      @input="relativeDetailValidation == true ? relativeDetailValidation = false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="relativeDetailValidation"
                      class="text-danger"
                    >
                      {{ relativeDetailError }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>

          <b-card
            header-tag="header"
          >
            <template #header>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                class="primary-button-with-background mr-3"
              >
                <feather-icon
                  icon="GridIcon"
                  class=""
                  size="20"
                />
              </b-button>
              <h3 class="align-middle mr-auto">
                <small>section 3</small>
                Product / Service Provided and Payment Terms
              </h3>
            </template>
            <b-row>
              <b-col cols="md-6">
                <b-form-group
                  label="Type of Product/Service Provided*"
                  label-for="h-booking-providedServiceType"
                  label-cols-md="5"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Name"
                    vid="providedServiceType"
                    rules="required"
                  >
                    <b-form-input
                      id="h-booking-providedServiceType"
                      v-model="providedServiceType"
                      placeholder="Type or Select from List"
                      :state="(errors.length > 0 || providedServiceTypeValidation) ? false : null"
                      name="providedServiceType"
                      @input="providedServiceTypeValidation == true ? providedServiceTypeValidation = false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="providedServiceTypeValidation"
                      class="text-danger"
                    >
                      {{ providedServiceTypeError }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="md-6" />
              <b-col cols="md-12">
                <hr class="dividerHR">
              </b-col>
              <!-- Cost -->
              <b-col
                cols="12"
              >
                <b-form-group
                  label="Payment Terms"
                  label-for="paymentTerms"
                  label-cols-md="2"
                  class="auto_width_label"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Payment Terms"
                    rules=""
                  >
                    <b-form-radio-group
                      id="paymentTerms"
                      v-model="paymentTerms"
                      buttons
                      button-variant="outline-primary"
                      class="spaceBetweenRadio"
                    >
                      <b-form-radio
                        v-for="(option, index) in paymentTermsOptions"
                        :key="index"
                        :value="option.value"
                        class="mb-50"
                        :state="(errors.length > 0) ? false : null"
                      >
                        <feather-icon
                          v-if="paymentTerms === option.value"
                          icon="CheckIcon"
                          class="mr-50"
                          size="16"
                        />
                        {{ option.text }}
                      </b-form-radio>
                    </b-form-radio-group>
                    <br>

                    <small
                      v-if="paymentTermsValidation"
                      class="text-danger"
                    >
                      {{ paymentTermsError }}
                    </small>
                  </validation-provider>
                  <validation-provider
                    v-if="paymentTerms == 'Others'"
                    #default="{ errors }"
                    vid="paymentTermsDescription"
                  >
                    <b-form-input
                      id="h-booking-paymentTermsDescription"
                      v-model="paymentTermsDescription"
                      placeholder="Please specify"
                      :state="(errors.length > 0 || paymentTermsDescriptionValidation) ? false : null"
                      name="paymentTermsDescription"
                      @input="paymentTermsDescriptionValidation == true ? paymentTermsDescriptionValidation = false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="paymentTermsDescriptionValidation"
                      class="text-danger"
                    >
                      {{ paymentTermsDescriptionError }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>

          <b-card
            header-tag="header"
          >
            <template #header>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                class="primary-button-with-background mr-3"
              >
                <feather-icon
                  icon="GridIcon"
                  class=""
                  size="20"
                />
              </b-button>
              <h3 class="align-middle mr-auto">
                <small>section 4</small>
                Banking Information
              </h3>
            </template>
            <b-row>
              <b-col cols="md-6">
                <b-form-group
                  label="Account Payee Name*"
                  label-for="h-booking-accountPayeeName"
                  label-cols-md="4"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Account Payee Name"
                    vid="accountPayeeName"
                    rules="required"
                  >
                    <b-form-input
                      id="h-booking-accountPayeeName"
                      v-model="accountPayeeName"
                      :state="(errors.length > 0 || accountPayeeNameValidation) ? false : null"
                      name="accountPayeeName"
                      placeholder="ABC Company"
                      @input="companyNameValidation == true ? accountPayeeNameValidation = false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="accountPayeeNameValidation"
                      class="text-danger"
                    >
                      {{ accountPayeeNameError }}
                    </small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Bank Name*"
                  label-for="h-booking-bankName"
                  label-cols-md="4"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Bank Name"
                    vid="bankName"
                    rules="required"
                  >
                    <b-form-input
                      id="h-booking-bankName"
                      v-model="bankName"
                      :state="(errors.length > 0 || bankNameValidation) ? false : null"
                      name="bankName"
                      placeholder="OCBC Bank"
                      @input="bankNameValidation == true ? bankNameValidation = false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="bankNameValidation"
                      class="text-danger"
                    >
                      {{ bankNameError }}
                    </small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Bank Branch"
                  label-for="h-booking-bankBranch"
                  label-cols-md="4"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Bank Branch"
                    vid="bankBranch"
                    rules=""
                  >
                    <b-form-input
                      id="h-booking-bankBranch"
                      v-model="bankBranch"
                      :state="(errors.length > 0 || bankBranchValidation) ? false : null"
                      name="bankBranch"
                      placeholder="South"
                      @input="bankBranchValidation == true ? bankBranchValidation = false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="bankBranchValidation"
                      class="text-danger"
                    >
                      {{ bankBranchError }}
                    </small>
                  </validation-provider>
                </b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Account Currency"
                  vid="signatoryOption"
                  rules="required"
                >
                  <b-form-group
                    label="Account Currency"
                    label-for="h-activity-type-accountCurrency"
                    label-cols-md="4"
                    :state="(errors.length > 0 || accountCurrencyValidation) ? false : null"
                  >
                    <v-select
                      id="h-activity-type-accountCurrency"
                      v-model="accountCurrency"
                      label="title"
                      :options="currencies"
                      :reduce="title => title.code"
                      :clearable="false"
                    >
                      <!-- eslint-disable -->
                      <template #option="{ title, code }">
                        <span class="">{{ title }} ({{ code }})</span>
                      </template>

                      <template #selected-option="{ title, code }">
                        <span class="">{{ title }} ({{ code }})</span>
                      </template>
                      <!-- eslint-enable -->
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="accountCurrencyValidation"
                      class="text-danger"
                    >
                      {{ accountCurrencyError }}
                    </small>
                  </b-form-group>
                </validation-provider>
                <b-form-group
                  label="Swift Code (For Overseas)"
                  label-for="h-booking-swiftCode"
                  label-cols-md="4"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Swift Code (For Overseas)"
                    vid="swiftCode"
                  >
                    <b-form-input
                      id="h-booking-swiftCode"
                      v-model="swiftCode"
                      :state="(errors.length > 0 || swiftCodeValidation) ? false : null"
                      name="swiftCode"
                      placeholder="OCBCSG"
                      @input="swiftCodeValidation == true ? swiftCodeValidation = false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="swiftCodeValidation"
                      class="text-danger"
                    >
                      {{ swiftCodeError }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="md-6">
                <b-form-group
                  label="Bank Account No."
                  label-for="h-booking-bankAccountNo"
                  label-cols-md="4"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Bank Account No."
                    vid="bankAccountNo"
                    rules=""
                  >
                    <b-form-input
                      id="h-booking-bankAccountNo"
                      v-model="bankAccountNo"
                      :state="(errors.length > 0 || bankAccountNoValidation) ? false : null"
                      name="bankAccountNo"
                      placeholder="123-45678-9"
                      @input="bankAccountNoValidation == true ? bankAccountNoValidation = false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="bankAccountNoValidation"
                      class="text-danger"
                    >
                      {{ bankAccountNoError }}
                    </small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Address"
                  label-for="h-booking-bankAddress"
                  label-cols-md="4"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Address"
                    vid="bankAddress"
                    rules=""
                  >
                    <b-form-textarea
                      id="h-booking-bankAddress"
                      v-model="bankAddress"
                      trim
                      placeholder="1 Chulia Street, #01-01, Singapore 888888"
                      :state="bankAddressValidation == true ? bankAddressValidation = false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="bankAddressValidation"
                      class="text-danger"
                    >
                      {{ bankAddressError }}
                    </small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Email Address"
                  label-for="h-booking-bankEmail"
                  label-cols-md="4"
                  description="For payment notification"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Email Address"
                    vid="bankEmail"
                    rules="email"
                  >
                    <b-form-input
                      id="h-booking-bankEmail"
                      v-model="bankEmail"
                      :state="(errors.length > 0 || bankEmailValidation) ? false : null"
                      name="bankEmail"
                      placeholder="admin@abc.com"
                      @input="bankEmailValidation == true ? bankEmailValidation = false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="bankEmailValidation"
                      class="text-danger"
                    >
                      {{ bankEmailError }}
                    </small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Paynow Details"
                  label-for="h-booking-paynowNo"
                  label-cols-md="4"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Paynow Details"
                    vid="paynowNo"
                    rules=""
                  >
                    <b-form-input
                      id="h-booking-paynowNo"
                      v-model="paynowNo"
                      :state="(errors.length > 0 || paynowNoValidation) ? false : null"
                      name="paynowNo"
                      placeholder="UEN number or mobile number"
                      @input="paynowNoValidation == true ? paynowNoValidation = false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="paynowNoValidation"
                      class="text-danger"
                    >
                      {{ paynowNoError }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>

          <b-card
            header-tag="header"
          >
            <template #header>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                class="primary-button-with-background mr-3"
              >
                <feather-icon
                  icon="GridIcon"
                  class=""
                  size="20"
                />
              </b-button>
              <h3 class="align-middle mr-auto">
                <small>section 5</small>
                Certifications Obtained
              </h3>
            </template>
            <b-row>
              <b-col
                cols="12"
                class="certificates"
              >
                <b-form-group
                  label="Please attach supporting documents of Certifications in the next section."
                  label-for="h-booking-certificates"
                  label-cols-md="12"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Certifications"
                    vid="certificates"
                    rules=""
                  >
                    <b-form-checkbox-group
                      id="h-booking-certificates"
                      v-model="certificates"
                      button-variant="outline-primary"
                      buttons
                      class="spaceBetweenRadio"
                      multiple
                    >
                      <b-form-checkbox
                        v-for="(opt, kindex) in optionsChekcbox"
                        :key="kindex"
                        :value="opt.value"
                        class="mb-50 customCheckbox"
                      >
                        <feather-icon
                          v-if="certificates.includes(opt.value)"
                          icon="CheckIcon"
                          class="mr-50"
                          size="16"
                        />
                        <span class="align-middle">{{ opt.text }}</span>
                      </b-form-checkbox>
                    </b-form-checkbox-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="certificatesValidation"
                      class="text-danger"
                    >
                      {{ certificatesError }}
                    </small>
                  </validation-provider>
                  <validation-provider
                    v-if="certificates.includes('Others')"
                    #default="{ errors }"
                    vid="otherCertificates"
                  >
                    <b-form-input
                      id="h-booking-otherCertificates"
                      v-model="otherCertificates"
                      placeholder="Please specify"
                      :state="(errors.length > 0 ) ? false : null"
                      name="otherCertificates"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>

          <b-card
            header-tag="header"
          >
            <template #header>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                class="primary-button-with-background mr-3"
              >
                <feather-icon
                  icon="GridIcon"
                  class=""
                  size="20"
                />
              </b-button>
              <h3 class="align-middle mr-auto">
                <small>section 6</small>
                Supporting Documents, Files and Images
              </h3>
            </template>
            <b-row>
              <b-col
                cols="12"
              >
                <vue-dropzone
                  id="event-images"
                  ref="myVueDropzone"
                  :options="dropzoneOptions"
                  :use-custom-slot="true"
                  @vdropzone-files-added="processFile"
                  @vdropzone-removed-file="fileRemoved"
                  @vdropzone-error="handleError"
                >
                  <div class="dropzone-custom-content">
                    <h3 class="dropzone-custom-title">
                      Drop files here or click to upload
                    </h3>
                    <div class="subtitle">
                      File should not bigger than 5 mb. JPEG, PNG, or PDF only
                    </div>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="button"
                      variant="primary"
                      class="mt-2"
                    >
                      Upload file
                    </b-button>
                  </div>
                </vue-dropzone>
              </b-col>
            </b-row>
          </b-card>

          <b-card
            header-tag="header"
          >
            <template #header>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                class="primary-button-with-background mr-3"
              >
                <feather-icon
                  icon="GridIcon"
                  class=""
                  size="20"
                />
              </b-button>
              <h3 class="align-middle mr-auto">
                Signatories
              </h3>
            </template>
            <b-row class="no_Signatories">
              <b-col cols="12">
                <div class="info_item">
                  <feather-icon
                    icon="InfoIcon"
                    class=""
                    size="20"
                  />
                  <span class="align-middle">Please confirm the names of signatories required to sign on this form.</span>
                </div>
              </b-col>
              <b-col cols="md-6">
                <validation-provider
                  #default="{ errors }"
                  name="Number of Signatory Required"
                  vid="signatoryOption"
                  rules="required"
                >
                  <b-form-group
                    label="Number of Signatory Required*"
                    label-for="h-activity-type-signatoryOption"
                    label-cols-md="5"
                    :state="(errors.length > 0 || signatoryOptionValidation) ? false : null"
                  >
                    <v-select
                      id="h-activity-type-signatoryOption"
                      v-model="signatoryOption"
                      label="title"
                      :options="signatoryOptions"
                      :reduce="title => title.code"
                      :clearable="false"
                      @input="changeSignatory()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="signatoryOptionValidation"
                      class="text-danger"
                    >
                      {{ signatoryOptionError }}
                    </small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="md-6" />
            </b-row>

            <hr class="dividerHR">
            <div v-if="signatoryOption">
              <b-row
                v-for="(opt, key) in loas"
                :key="key"
              >
                <b-col
                  v-if="signatoryOption > key"
                  cols="md-6"
                >
                  <validation-provider
                    #default="{ errors }"
                    name=""
                    :vid="'event-custom-field-' + key"
                    rules="required"
                  >
                    <b-form-group
                      label-cols-md="5"
                      :label="opt.title"
                      :label-for="'event-custom-field-' + key"
                      :state="(errors.length > 0) ? false : null"
                    >
                      <v-select
                        id="h-bookings-dutyOfficer"
                        v-model="signatoryAssigns[key]"
                        label="name"
                        placeholder="Select an option"
                        :disabled="key < 1"
                        :options="userOptions"
                        :reduce="name => name._id"
                        :clearable="false"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </div>
          </b-card>

          <b-nav class="wrap-border save-nav">
            <li
              class="nav-item ml-auto"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                :to="{ name: 'purchasing-vendors-index', params: { type: 'all-vendors'} }"
              >
                Cancel
              </b-button>
            </li>
            <li
              class="nav-item mr-1"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="success"
                :disabled="invalid"
                @click="enablePreview()"
              >
                <feather-icon
                  icon="EyeIcon"
                  class="mr-50"
                />
                <span class="align-middle">Preview Before Sending</span>
              </b-button>
            </li>
          </b-nav>
        </validation-observer>
      </b-form>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BForm, BButton, BNav, BCardText, BDropdown, BDropdownItem,
  BFormRadioGroup, BFormRadio, BFormCheckboxGroup, BFormCheckbox, BCardBody, BTableSimple, BTbody, BTr, BTh, BTd, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vue2Dropzone from 'vue2-dropzone'
import vSelect from 'vue-select'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { required } from '@validations'
import store from '@/store/index'
import html2pdf from 'html2pdf.js'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import { heightTransition } from '@core/mixins/ui/transition'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BButton,
    BNav,
    BFormRadioGroup,
    BFormRadio,
    BFormCheckboxGroup,
    BFormCheckbox,
    BCardBody,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BCardText,
    vSelect,
    BImg,
    BDropdown,
    BDropdownItem,
    vueDropzone: vue2Dropzone,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      vendorPreview: false,
      secondAgree: '',
      user: store.state.auth.userData,
      vendor: {},
      companyName: '',
      companyRegistration: '',
      companyGSTRegistration: '',
      individualNRIC: '',
      companyContact: '',
      companyFax: '',
      companyAddress: '',
      salesPersonName: '',
      salesPersonContact: '',
      salesPersonEmail: '',
      salesPersonEmail2: '',
      relativeInNirvana: '',
      relativeDetail: '',
      paymentTerms: '',
      paymentTermsDescription: '',
      accountPayeeName: '',
      bankName: '',
      bankBranch: '',
      accountCurrency: 'SGD',
      swiftCode: '',
      bankAccountNo: '',
      bankAddress: '',
      bankEmail: '',
      paynowNo: '',
      otherCertificates: '',
      providedServiceType: '',
      signatoryOption: 4,
      signatoryOptionOld: 4,
      certificates: [],
      attachments: [],
      companyNameError: 'Valid Company Name is required',
      companyNameValidation: false,
      companyContactError: 'Valid Tel is required',
      companyContactValidation: false,
      companyRegistrationError: 'Valid Registration No. is required',
      companyRegistrationValidation: false,
      companyFaxError: 'Valid Fax is required',
      companyFaxValidation: false,
      companyGSTRegistrationError: 'Valid GST Registration No. is required',
      companyGSTRegistrationValidation: false,
      companyAddressError: 'Valid Registered Address is required',
      companyAddressValidation: false,
      individualNRICError: 'Valid NRIC No. (For Individual Vendor) is required',
      individualNRICValidation: false,
      salesPersonNameError: 'Valid Name is required',
      salesPersonNameValidation: false,
      salesPersonEmailError: 'Valid Email Address is required',
      salesPersonEmailValidation: false,
      salesPersonContactError: 'Valid Contact No. is required',
      salesPersonContactValidation: false,
      salesPersonEmail2Error: 'Valid Email Address 2 is required',
      salesPersonEmail2Validation: false,
      accountPayeeNameError: 'Valid Account Payee Name is required',
      accountPayeeNameValidation: false,
      bankAccountNoError: 'Valid Bank Account No. is required',
      bankAccountNoValidation: false,
      bankNameError: 'Valid Bank Name is required',
      bankNameValidation: false,
      bankAddressError: 'Valid Address is required',
      bankAddressValidation: false,
      bankBranchError: 'Valid Bank Branch is required',
      bankBranchValidation: false,
      accountCurrencyError: 'Valid Account Currency is required',
      accountCurrencyValidation: false,
      swiftCodeError: 'Valid Swift Code (For Overseas) is required',
      swiftCodeValidation: false,
      bankEmailError: 'Valid Email Address is required',
      bankEmailValidation: false,
      paynowNoError: 'Paynow Detail is required',
      paynowNoValidation: false,
      certificatesError: 'Valid Certifications is required',
      certificatesValidation: false,
      signatoryOptionError: 'Valid Number of Signatory is required',
      signatoryOptionValidation: false,
      relativeInNirvanaError: 'Valid NV Group is required',
      relativeInNirvanaValidation: false,
      relativeDetailValidation: false,
      relativeDetailError: '',
      paymentTermsDescriptionValidation: false,
      paymentTermsDescriptionError: '',
      providedServiceTypeValidation: false,
      providedServiceTypeError: '',
      paymentTermsError: 'Valid Payment Terms is required',
      paymentTermsValidation: false,
      userOptions: [],
      signatoryAssigns: [],
      signatoryAssignsError: 'Valid Signatory is required',
      signatoryAssignsValidation: false,
      optionsChekcbox: [
        { text: 'ISO 9001 Quality Management', value: 'ISO 9001 Quality Management' },
        { text: 'ISO 14001 Environment Management', value: 'ISO 14001 Environment Management' },
        { text: 'OHSAS 18001 / ISO 45001 Occupational Safety & Health Management', value: 'OHSAS 18001 / ISO 45001 Occupational Safety & Health Management' },
        { text: 'No Certificate', value: 'No Certificate' },
        { text: 'Others', value: 'Others' },
      ],
      nvGroupOptionOptions: [
        { text: 'Yes', value: 'yes' },
        { text: 'No', value: 'no' },
      ],
      paymentTermsOptions: [
        { text: '60 Days', value: '60 Days' },
        { text: '45 Days', value: '45 Days' },
        { text: '30 Days', value: '30 Days' },
        { text: '15 Days', value: '15 Days' },
        { text: '7 Days', value: '7 Days' },
        { text: 'C.O.D', value: 'C.O.D' },
        { text: 'Others', value: 'Others' },
      ],
      currencies: [
        { title: 'United Arab Emirates Dirham', code: 'AED' },
        { title: 'Afghan Afghani', code: 'AFN' },
        { title: 'Albanian Lek', code: 'ALL' },
        { title: 'Armenian Dram', code: 'AMD' },
        { title: 'Netherlands Antillean Guilder', code: 'ANG' },
        { title: 'Angolan Kwanza', code: 'AOA' },
        { title: 'Argentine Peso', code: 'ARS' },
        { title: 'Australian Dollar', code: 'AUD' },
        { title: 'Aruban Florin', code: 'AWG' },
        { title: 'Azerbaijani Manat', code: 'AZN' },
        { title: 'Bosnia-Herzegovina Convertible Mark', code: 'BAM' },
        { title: 'Barbadian Dollar', code: 'BBD' },
        { title: 'Bangladeshi Taka', code: 'BDT' },
        { title: 'Bulgarian Lev', code: 'BGN' },
        { title: 'Bahraini Dinar', code: 'BHD' },
        { title: 'Burundian Franc', code: 'BIF' },
        { title: 'Bermudan Dollar', code: 'BMD' },
        { title: 'Brunei Dollar', code: 'BND' },
        { title: 'Bolivian Boliviano', code: 'BOB' },
        { title: 'Brazilian Real', code: 'BRL' },
        { title: 'Bahamian Dollar', code: 'BSD' },
        { title: 'Bitcoin', code: 'BTC' },
        { title: 'Bhutanese Ngultrum', code: 'BTN' },
        { title: 'Botswanan Pula', code: 'BWP' },
        { title: 'Belarusian Ruble', code: 'BYN' },
        { title: 'Belarusian Ruble (pre-2016)', code: 'BYR' },
        { title: 'Belize Dollar', code: 'BZD' },
        { title: 'Canadian Dollar', code: 'CAD' },
        { title: 'Congolese Franc', code: 'CDF' },
        { title: 'Swiss Franc', code: 'CHF' },
        { title: 'Chilean Unit of Account (UF)', code: 'CLF' },
        { title: 'Chilean Peso', code: 'CLP' },
        { title: 'Chinese Yuan (Offshore)', code: 'CNH' },
        { title: 'Chinese Yuan', code: 'CNY' },
        { title: 'Colombian Peso', code: 'COP' },
        { title: 'Costa Rican Colón', code: 'CRC' },
        { title: 'Cuban Convertible Peso', code: 'CUC' },
        { title: 'Cuban Peso', code: 'CUP' },
        { title: 'Cape Verdean Escudo', code: 'CVE' },
        { title: 'Czech Republic Koruna', code: 'CZK' },
        { title: 'Djiboutian Franc', code: 'DJF' },
        { title: 'Danish Krone', code: 'DKK' },
        { title: 'Dominican Peso', code: 'DOP' },
        { title: 'Algerian Dinar', code: 'DZD' },
        { title: 'Estonian Kroon', code: 'EEK' },
        { title: 'Egyptian Pound', code: 'EGP' },
        { title: 'Eritrean Nakfa', code: 'ERN' },
        { title: 'Ethiopian Birr', code: 'ETB' },
        { title: 'Euro', code: 'EUR' },
        { title: 'Fijian Dollar', code: 'FJD' },
        { title: 'Falkland Islands Pound', code: 'FKP' },
        { title: 'British Pound Sterling', code: 'GBP' },
        { title: 'Georgian Lari', code: 'GEL' },
        { title: 'Guernsey Pound', code: 'GGP' },
        { title: 'Ghanaian Cedi', code: 'GHS' },
        { title: 'Gibraltar Pound', code: 'GIP' },
        { title: 'Gambian Dalasi', code: 'GMD' },
        { title: 'Guinean Franc', code: 'GNF' },
        { title: 'Guatemalan Quetzal', code: 'GTQ' },
        { title: 'Guyanaese Dollar', code: 'GYD' },
        { title: 'Hong Kong Dollar', code: 'HKD' },
        { title: 'Honduran Lempira', code: 'HNL' },
        { title: 'Croatian Kuna', code: 'HRK' },
        { title: 'Haitian Gourde', code: 'HTG' },
        { title: 'Hungarian Forint', code: 'HUF' },
        { title: 'Indonesian Rupiah', code: 'IDR' },
        { title: 'Israeli New Sheqel', code: 'ILS' },
        { title: 'Manx pound', code: 'IMP' },
        { title: 'Indian Rupee', code: 'INR' },
        { title: 'Iraqi Dinar', code: 'IQD' },
        { title: 'Iranian Rial', code: 'IRR' },
        { title: 'Icelandic Króna', code: 'ISK' },
        { title: 'Jersey Pound', code: 'JEP' },
        { title: 'Jamaican Dollar', code: 'JMD' },
        { title: 'Jordanian Dinar', code: 'JOD' },
        { title: 'Japanese Yen', code: 'JPY' },
        { title: 'Kenyan Shilling', code: 'KES' },
        { title: 'Kyrgystani Som', code: 'KGS' },
        { title: 'Cambodian Riel', code: 'KHR' },
        { title: 'Comorian Franc', code: 'KMF' },
        { title: 'North Korean Won', code: 'KPW' },
        { title: 'South Korean Won', code: 'KRW' },
        { title: 'Kuwaiti Dinar', code: 'KWD' },
        { title: 'Cayman Islands Dollar', code: 'KYD' },
        { title: 'Kazakhstani Tenge', code: 'KZT' },
        { title: 'Laotian Kip', code: 'LAK' },
        { title: 'Lebanese Pound', code: 'LBP' },
        { title: 'Sri Lankan Rupee', code: 'LKR' },
        { title: 'Liberian Dollar', code: 'LRD' },
        { title: 'Lesotho Loti', code: 'LSL' },
        { title: 'Libyan Dinar', code: 'LYD' },
        { title: 'Moroccan Dirham', code: 'MAD' },
        { title: 'Moldovan Leu', code: 'MDL' },
        { title: 'Malagasy Ariary', code: 'MGA' },
        { title: 'Macedonian Denar', code: 'MKD' },
        { title: 'Myanma Kyat', code: 'MMK' },
        { title: 'Mongolian Tugrik', code: 'MNT' },
        { title: 'Macanese Pataca', code: 'MOP' },
        { title: 'Mauritanian Ouguiya (pre-2018)', code: 'MRO' },
        { title: 'Mauritanian Ouguiya', code: 'MRU' },
        { title: 'Maltese Lira', code: 'MTL' },
        { title: 'Mauritian Rupee', code: 'MUR' },
        { title: 'Maldivian Rufiyaa', code: 'MVR' },
        { title: 'Malawian Kwacha', code: 'MWK' },
        { title: 'Mexican Peso', code: 'MXN' },
        { title: 'Malaysian Ringgit', code: 'MYR' },
        { title: 'Mozambican Metical', code: 'MZN' },
        { title: 'Namibian Dollar', code: 'NAD' },
        { title: 'Nigerian Naira', code: 'NGN' },
        { title: 'Nicaraguan Córdoba', code: 'NIO' },
        { title: 'Norwegian Krone', code: 'NOK' },
        { title: 'Nepalese Rupee', code: 'NPR' },
        { title: 'New Zealand Dollar', code: 'NZD' },
        { title: 'Omani Rial', code: 'OMR' },
        { title: 'Panamanian Balboa', code: 'PAB' },
        { title: 'Peruvian Nuevo Sol', code: 'PEN' },
        { title: 'Papua New Guinean Kina', code: 'PGK' },
        { title: 'Philippine Peso', code: 'PHP' },
        { title: 'Pakistani Rupee', code: 'PKR' },
        { title: 'Polish Zloty', code: 'PLN' },
        { title: 'Paraguayan Guarani', code: 'PYG' },
        { title: 'Qatari Rial', code: 'QAR' },
        { title: 'Romanian Leu', code: 'RON' },
        { title: 'Serbian Dinar', code: 'RSD' },
        { title: 'Russian Ruble', code: 'RUB' },
        { title: 'Rwandan Franc', code: 'RWF' },
        { title: 'Saudi Riyal', code: 'SAR' },
        { title: 'Solomon Islands Dollar', code: 'SBD' },
        { title: 'Seychellois Rupee', code: 'SCR' },
        { title: 'Sudanese Pound', code: 'SDG' },
        { title: 'Swedish Krona', code: 'SEK' },
        { title: 'Singapore Dollar', code: 'SGD' },
        { title: 'Saint Helena Pound', code: 'SHP' },
        { title: 'Sierra Leonean Leone', code: 'SLL' },
        { title: 'Somali Shilling', code: 'SOS' },
        { title: 'Surinamese Dollar', code: 'SRD' },
        { title: 'South Sudanese Pound', code: 'SSP' },
        { title: 'São Tomé and Príncipe Dobra (pre-2018)', code: 'STD' },
        { title: 'São Tomé and Príncipe Dobra', code: 'STN' },
        { title: 'Salvadoran Colón', code: 'SVC' },
        { title: 'Syrian Pound', code: 'SYP' },
        { title: 'Swazi Lilangeni', code: 'SZL' },
        { title: 'Thai Baht', code: 'THB' },
        { title: 'Tajikistani Somoni', code: 'TJS' },
        { title: 'Turkmenistani Manat', code: 'TMT' },
        { title: 'Tunisian Dinar', code: 'TND' },
        { title: 'Tongan Paʻanga', code: 'TOP' },
        { title: 'Turkish Lira', code: 'TRY' },
        { title: 'Trinidad and Tobago Dollar', code: 'TTD' },
        { title: 'New Taiwan Dollar', code: 'TWD' },
        { title: 'Tanzanian Shilling', code: 'TZS' },
        { title: 'Ukrainian Hryvnia', code: 'UAH' },
        { title: 'Ugandan Shilling', code: 'UGX' },
        { title: 'United States Dollar', code: 'USD' },
        { title: 'Uruguayan Peso', code: 'UYU' },
        { title: 'Uzbekistan Som', code: 'UZS' },
        { title: 'Venezuelan Bolívar Soberano', code: 'VES' },
        { title: 'Vietnamese Dong', code: 'VND' },
        { title: 'Vanuatu Vatu', code: 'VUV' },
        { title: 'Samoan Tala', code: 'WST' },
        { title: 'CFA Franc BEAC', code: 'XAF' },
        { title: 'Silver (troy ounce)', code: 'XAG' },
        { title: 'Gold (troy ounce)', code: 'XAU' },
        { title: 'East Caribbean Dollar', code: 'XCD' },
        { title: 'Special Drawing Rights', code: 'XDR' },
        { title: 'CFA Franc BCEAO', code: 'XOF' },
        { title: 'Palladium Ounce', code: 'XPD' },
        { title: 'CFP Franc', code: 'XPF' },
        { title: 'Platinum Ounce', code: 'XPT' },
        { title: 'Yemeni Rial', code: 'YER' },
        { title: 'South African Rand', code: 'ZAR' },
        { title: 'Zambian Kwacha (pre-2013)', code: 'ZMK' },
        { title: 'Zambian Kwacha', code: 'ZMW' },
      ],
      signatoryOptions: [
        { title: '4', code: '4' },
      ],
      loas: [
        { title: '1. Prepared by', code: 'Prepared by' },
        { title: '2. Received by', code: 'Received by' },
        { title: '3. Verified by', code: 'Verified by' },
        { title: '4. Approved by', code: 'Approved by' },
      ],
      dropzoneOptions: {
        autoProcessQueue: false,
        url: 'https://httpbin.org/post',
        acceptedFiles: 'image/jpeg,image/png,application/pdf',
        maxFilesize: 5,
        addRemoveLinks: true,
        dictFileTooBig: 'File is too big ({{filesize}}mb). Max filesize: {{maxFilesize}}mb.',
        // maxFiles: 1,
      },
      fileName: 'compressed.tracemonkey-pldi-09.pdf',
      path: '/lib/pdfjs/web/viewer.html',
      pdfData: '',
      pdfTotalPage: 0,
      elHeight: 0,
      gapHeight: 0,
      showPDF: true,
      showAttachment: false,
      selectedFileName: '',
      currentFileIndex: 1,
      imageSrc: '',

      // validation rules
      required,
    }
  },

  created() {
    this.$http.get('purchase/purchase-request/create/extra-options')
      .then(response => {
        this.userOptions = response.data.userOptions ?? []
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })

    this.$http.get(`purchase/vendors/${this.$route.params.id}`)
      .then(response => {
        this.vendor = response.data.data
        this.companyName = response.data.data.companyName || ''
        this.companyRegistration = response.data.data.companyRegistration || ''
        this.companyGSTRegistration = response.data.data.companyGSTRegistration || ''
        this.individualNRIC = response.data.data.individualNRIC || ''
        this.companyContact = response.data.data.companyContact || ''
        this.companyFax = response.data.data.companyFax || ''
        this.companyAddress = response.data.data.companyAddress || ''
        this.salesPersonName = response.data.data.salesPersonName || ''
        this.salesPersonContact = response.data.data.salesPersonContact || ''
        this.salesPersonEmail = response.data.data.salesPersonEmail || ''
        this.salesPersonEmail2 = response.data.data.salesPersonEmail2 || ''
        this.relativeInNirvana = response.data.data.relativeInNirvana || ''
        this.relativeDetail = response.data.data.relativeDetail || ''
        this.paymentTerms = response.data.data.paymentTerms || ''
        this.paymentTermsDescription = response.data.data.paymentTermsDescription || ''
        this.accountPayeeName = response.data.data.accountPayeeName || ''
        this.bankName = response.data.data.bankName || ''
        this.bankBranch = response.data.data.bankBranch || ''
        this.accountCurrency = response.data.data.accountCurrency || ''
        this.swiftCode = response.data.data.swiftCode || ''
        this.bankAccountNo = response.data.data.bankAccountNo || ''
        this.bankAddress = response.data.data.bankAddress || ''
        this.bankEmail = response.data.data.bankEmail || ''
        this.paynowNo = response.data.data.paynowNo || ''
        this.certificates = response.data.data.certificates || ''
        this.otherCertificates = response.data.data.otherCertificates || ''
        this.providedServiceType = response.data.data.providedServiceType || ''

        response.data.data.attachments.forEach(arrayItem => {
          const file = {
            size: (arrayItem.size * 1024 * 1024), name: arrayItem.name, type: arrayItem.type, description: arrayItem.description,
          }
          this.$refs.myVueDropzone.manuallyAddFile(file, arrayItem.data)
          this.attachments.push(arrayItem)
        })
        if (response.data.data.signatories.length) {
          this.signatoryOption = 4
          response.data.data.signatories.forEach((arrayItem, key) => {
            if (key <= 4) {
              this.signatoryAssigns.push(arrayItem.user)
            }
          })
        } else {
          this.signatoryOption = 4

          this.signatoryAssigns.push(this.user._id)
        }
        this.signatoryOptionOld = this.signatoryOption
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })

    // this.signatoryAssigns.push(this.user._id)
  },

  beforeRouteLeave(to, from, next) {
    const bcLength = store.state.breadcrumb.breadcrumbs.length
    if (bcLength > 2) {
      store.commit('breadcrumb/REMOVE_BREADCRUMB')
    }
    next()
  },

  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    base64ToUint8Array(base64) {
      const raw = atob(base64)
      const uint8Array = new Uint8Array(raw.length)
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < raw.length; i++) {
        uint8Array[i] = raw.charCodeAt(i)
      }
      return uint8Array
    },
    changeFileIndex(key) {
      this.currentFileIndex = key
      if (key === 1) {
        this.selectedFileName = this.companyName
        this.showAttachment = false
      } else {
        this.selectedFileName = this.attachments[key - 2].name
        if (this.attachments[key - 2].type.includes('pdf')) {
          const base64Raw = this.attachments[key - 2].data.split(',')[1]
          const pdfDataJS = this.base64ToUint8Array(base64Raw)
          const pdfViewerFrame2 = document.getElementById('attachmentpdf')
          pdfViewerFrame2.onload = () => {
            pdfViewerFrame2.contentWindow.PDFViewerApplication.open(pdfDataJS)
          }
          pdfViewerFrame2.setAttribute('src', '/lib/pdfjs/web/viewer.html?file=')
          this.showPDF = true
        } else {
          this.imageSrc = this.attachments[key - 2].data
          this.showPDF = false
        }
        this.showAttachment = true
      }
    },
    async enablePreview() {
      const newMeta = {
        active: true,
        text: 'Preview',
      }
      store.commit('breadcrumb/UPDATE_BREADCRUMB', newMeta)
      store.commit('appConfig/UPDATE_LOADER', true)
      const vm = this
      await this.calculateTotalPage()
      const element = document.getElementById('pdfGenerateDiv')
      const opt = {
        margin: 1,
        filename: 'myfile.pdf',
        pagebreak: { mode: ['avoid-all', 'css', 'legacy'], after: '.page-section' },
        image: { type: 'jpeg', quality: 1 },
        html2canvas: {
          scale: 2,
          dpi: 192,
          // windowWidth: 1024,
          letterRendering: true,
        },
        jsPDF: { unit: 'mm', format: [297, 210], orientation: 'p' },
      }

      html2pdf().set(opt).from(element).toPdf()
        .get('pdf')
        .then(pdf => {
          vm.pdfData = pdf.output('datauristring')
          const base64Raw = vm.pdfData.split(',')[1]
          const pdfDataJS = vm.base64ToUint8Array(base64Raw)
          const pdfViewerFrame = document.getElementById('docpdf')
          // eslint-disable-next-line func-names
          pdfViewerFrame.onload = () => {
            pdfViewerFrame.contentWindow.PDFViewerApplication.open(pdfDataJS)
          }
          pdfViewerFrame.setAttribute('src', '/lib/pdfjs/web/viewer.html?file=')
          vm.currentFileIndex = 1
          vm.selectedFileName = vm.companyName
          vm.showAttachment = false
          window.scrollTo(0, 0)
          vm.vendorPreview = true
          store.commit('appConfig/UPDATE_LOADER', false)
        })
    },
    async calculateTotalPage() {
      this.pdfTotalPage = 1
      const logoHeight = document.getElementById('main-header').offsetHeight
      const footerHeight = document.getElementById('footer-div').offsetHeight
      const contentHeight = document.getElementById('content-div').offsetHeight
      // const section1Height = document.getElementById('section1').offsetHeight
      // const section2Height = document.getElementById('section2').offsetHeight
      // const section3Height = document.getElementById('section3').offsetHeight
      // const section4Height = document.getElementById('section4').offsetHeight
      // const section5Height = document.getElementById('section5').offsetHeight
      // while (tableHeight > contentHeight) {
      //   sliceEnd -= 1
      //   // console.log(contentHeight, tableHeight)
      //   this.chunkItemRequests = this.itemRequests.slice(sliceStart, sliceEnd)
      //   // console.log(sliceStart, sliceEnd)
      //   // eslint-disable-next-line no-await-in-loop
      //   await this.$nextTick()
      //   tableHeight = document.getElementById('content-div').offsetHeight - tableFooterHeight
      //   // console.log(tableHeight, contentHeight)
      // }
      // console.log(logoHeight, footerHeight, contentHeight)
      let remainingHeight = 0
      const dHeight = 1120
      remainingHeight = dHeight - (logoHeight + footerHeight + contentHeight)
      if (remainingHeight > 0) {
        this.gapHeight = remainingHeight
      }
      if (contentHeight >= (dHeight - (logoHeight + footerHeight))) {
        this.pdfTotalPage += 1
        this.gapHeight = dHeight - (logoHeight + footerHeight)
      }
    },
    enableEdit() {
      store.commit('breadcrumb/REMOVE_BREADCRUMB')
      this.vendorPreview = false
    },
    finalConfirmation() {
      this.$swal({
        title: 'Submit this Vendor Registration Form?',
        html: `You are proceeding to sign this Vendor Registration Form. The form will then be ready for ${this.resolveNextSignatoryName()} for signing.`,
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, go back',
        confirmButtonText: 'Yes, Proceed',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.submitForm()
          }
        })
    },
    resolveSignatoryName(id) {
      const user = this.userOptions.find(o => o._id === id)
      if (user) {
        return user.name
      }

      return ''
    },
    resolveSignatoryDepartment(id) {
      const user = this.userOptions.find(o => o._id === id)
      if (user) {
        return user.department.length ? user.department[0].name : ''
      }

      return ''
    },
    resolveNextSignatoryName() {
      const user = this.userOptions.find(o => o._id === this.signatoryAssigns[1])
      if (user) {
        return user.name
      }

      return ''
    },
    processFile(file) {
      const acceptedTypes = ['image/jpeg', 'image/png', 'application/pdf']
      file.forEach(element => {
        if (acceptedTypes.includes(element.type)) {
          if (element.size <= 5 * 1024 * 1024) {
            const exist = this.attachments.find(o => o.name === element.name && o.size === (element.size / (1024 * 1024)).toFixed(2))
            if (exist) {
              setTimeout(() => {
                if (element.previewElement) {
                  element.previewElement.remove()
                }
              }, 500)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Duplicate File',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            } else {
              const reader = new FileReader()
              reader.readAsDataURL(element)

              reader.onload = event => {
                const fileObj = {}
                fileObj.name = element.name
                fileObj.description = ''
                fileObj.data = event.target.result
                fileObj.size = (element.size / (1024 * 1024)).toFixed(2)
                fileObj.type = element.type
                this.attachments.push(fileObj)
              }
            }
          }
        }
      })
    },
    fileRemoved(file) {
      if (file.dataURL) {
        this.attachments = this.attachments.filter(element => element.data !== file.dataURL)
      } else {
        this.attachments = this.attachments.filter(element => element.name !== file.name)
      }

      const previewLength = document.getElementsByClassName('dz-preview').length
      if (!previewLength) {
        this.$refs.myVueDropzone.removeAllFiles()
      }
    },
    handleError(file, message) {
      this.$refs.myVueDropzone.removeFile(file)
      if (message !== 'Upload canceled.') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    async changeSignatory() {
      // eslint-disable-next-line radix
      this.signatoryOption = parseInt(this.signatoryOption)
      if (this.signatoryOptionOld > this.signatoryOption) {
        await this.$nextTick()
        this.$swal({
          title: 'Are You Sure?',
          html: 'You are changing the number of signatory.',
          // eslint-disable-next-line global-require
          imageUrl: require('@/assets/images/icons/warning.png'),
          reverseButtons: true,
          showCancelButton: true,
          allowOutsideClick: false,
          cancelButtonText: 'No, go back',
          confirmButtonText: "Yes, I'm sure.",
          customClass: {
            confirmButton: 'btn btn-danger ml-1',
            cancelButton: 'btn btn-outline-primary',
          },
          buttonsStyling: false,
        })
          .then(result => {
            if (result.value) {
              this.signatoryOptionOld = this.signatoryOption
              // eslint-disable-next-line radix
              const intOption = parseInt(this.signatoryOption)
              if (this.signatoryAssigns.length > intOption) {
                this.signatoryAssigns = this.signatoryAssigns.slice(0, intOption)
              }
            } else {
              this.signatoryOption = this.signatoryOptionOld
              // eslint-disable-next-line radix
              const intOption = parseInt(this.signatoryOption)
              if (this.signatoryAssigns.length > intOption) {
                this.signatoryAssigns = this.signatoryAssigns.slice(0, intOption)
              }
            }
          })
      } else {
        this.signatoryOptionOld = this.signatoryOption
        // eslint-disable-next-line radix
        const intOption = parseInt(this.signatoryOption)
        if (this.signatoryAssigns.length > intOption) {
          this.signatoryAssigns = this.signatoryAssigns.slice(0, intOption)
        }
      }
    },
    submitForm() {
      this.$refs.vendorCreateForm.validate().then(success => {
        if (success) {
          const formData = new FormData()

          formData.append('url', window.location.origin)
          formData.append('companyName', this.companyName)
          formData.append('companyContact', this.companyContact)
          formData.append('companyRegistration', this.companyRegistration)
          formData.append('companyFax', this.companyFax)
          formData.append('companyGSTRegistration', this.companyGSTRegistration)
          formData.append('companyAddress', this.companyAddress)
          formData.append('individualNRIC', this.individualNRIC)
          formData.append('salesPersonName', this.salesPersonName)
          formData.append('salesPersonEmail', this.salesPersonEmail)
          formData.append('salesPersonContact', this.salesPersonContact)
          formData.append('salesPersonEmail2', this.salesPersonEmail2)
          formData.append('relativeInNirvana', this.relativeInNirvana)
          formData.append('relativeDetail', this.relativeDetail)
          formData.append('providedServiceType', this.providedServiceType)
          formData.append('paymentTerms', this.paymentTerms)
          formData.append('paymentTermsDescription', this.paymentTermsDescription)
          formData.append('accountPayeeName', this.accountPayeeName)
          formData.append('bankAccountNo', this.bankAccountNo)
          formData.append('bankName', this.bankName)
          formData.append('bankAddress', this.bankAddress)
          formData.append('bankBranch', this.bankBranch)
          formData.append('accountCurrency', this.accountCurrency)
          formData.append('swiftCode', this.swiftCode)
          formData.append('bankEmail', this.bankEmail)
          formData.append('paynowNo', this.paynowNo)
          formData.append('signatoryOption', this.signatoryOption)
          formData.append('certificates', JSON.stringify(this.certificates))
          formData.append('otherCertificates', this.otherCertificates)
          formData.append('attachments', JSON.stringify(this.attachments))
          formData.append('signatoryAssigns', JSON.stringify(this.signatoryAssigns))

          this.$http.patch(`purchase/vendors/${this.$route.params.id}/update`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              if (response.data.data) {
                this.$swal({
                  title: 'Successfully Updated!',
                  html: `${this.companyName}'s profile has been updated. Signatories will be notified.`,
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/save.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  allowOutsideClick: false,
                  confirmButtonText: 'View Entry',
                  customClass: {
                    confirmButton: 'btn btn-primary mr-1',
                    cancelButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
                  .then(result => {
                    if (result.value) {
                      this.$router.push({ name: 'purchasing-vendors-index', params: { type: 'all-vendors' } })
                    }
                  })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'companyName') {
                    this.companyNameError = validationError.msg
                    this.companyNameValidation = true
                  } else if (validationError.param === 'companyContact') {
                    this.companyContactError = validationError.msg
                    this.companyContactValidation = true
                  } else if (validationError.param === 'companyRegistration') {
                    this.companyRegistrationError = validationError.msg
                    this.companyRegistrationValidation = true
                  } else if (validationError.param === 'companyFax') {
                    this.companyFaxError = validationError.msg
                    this.companyFaxValidation = true
                  } else if (validationError.param === 'companyGSTRegistration') {
                    this.companyGSTRegistrationError = validationError.msg
                    this.companyGSTRegistrationValidation = true
                  } else if (validationError.param === 'companyAddress') {
                    this.companyAddressError = validationError.msg
                    this.companyAddressValidation = true
                  } else if (validationError.param === 'individualNRIC') {
                    this.individualNRICError = validationError.msg
                    this.individualNRICValidation = true
                  } else if (validationError.param === 'salesPersonName') {
                    this.salesPersonNameError = validationError.msg
                    this.salesPersonNameValidation = true
                  } else if (validationError.param === 'salesPersonEmail') {
                    this.salesPersonEmailError = validationError.msg
                    this.salesPersonEmailValidation = true
                  } else if (validationError.param === 'salesPersonContact') {
                    this.salesPersonContactError = validationError.msg
                    this.salesPersonContactValidation = true
                  } else if (validationError.param === 'salesPersonEmail2') {
                    this.salesPersonEmail2Error = validationError.msg
                    this.salesPersonEmail2Validation = true
                  } else if (validationError.param === 'relativeInNirvana') {
                    this.relativeInNirvanaError = validationError.msg
                    this.relativeInNirvanaValidation = true
                  } else if (validationError.param === 'paymentTerms') {
                    this.paymentTermsError = validationError.msg
                    this.paymentTermsValidation = true
                  } else if (validationError.param === 'paymentTermsDescription') {
                    this.paymentTermsDescriptionError = validationError.msg
                    this.paymentTermsDescriptionValidation = true
                  } else if (validationError.param === 'accountPayeeName') {
                    this.accountPayeeNameError = validationError.msg
                    this.accountPayeeNameValidation = true
                  } else if (validationError.param === 'bankAccountNo') {
                    this.bankAccountNoError = validationError.msg
                    this.bankAccountNoValidation = true
                  } else if (validationError.param === 'bankName') {
                    this.bankNameError = validationError.msg
                    this.bankNameValidation = true
                  } else if (validationError.param === 'bankAddress') {
                    this.bankAddressError = validationError.msg
                    this.bankAddressValidation = true
                  } else if (validationError.param === 'bankBranch') {
                    this.bankBranchError = validationError.msg
                    this.bankBranchValidation = true
                  } else if (validationError.param === 'accountCurrency') {
                    this.accountCurrencyError = validationError.msg
                    this.accountCurrencyValidation = true
                  } else if (validationError.param === 'swiftCode') {
                    this.swiftCodeError = validationError.msg
                    this.swiftCodeValidation = true
                  } else if (validationError.param === 'bankEmail') {
                    this.bankEmailError = validationError.msg
                    this.bankEmailValidation = true
                  } else if (validationError.param === 'paynowNo') {
                    this.paynowNoError = validationError.msg
                    this.paynowNoValidation = true
                  } else if (validationError.param === 'certificates') {
                    this.certificatesError = validationError.msg
                    this.certificatesValidation = true
                  } else if (validationError.param === 'signatoryOption') {
                    this.signatoryOptionError = validationError.msg
                    this.signatoryOptionValidation = true
                  }
                })

                const items = document.getElementsByClassName('text-danger')
                window.scrollTo({
                  top: items[0].offsetTop,
                  behavior: 'smooth',
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>

<style scoped>
  .removeText {
    color:#D91B35;
    cursor: pointer;
  }
  .addOptionText {
    color: #104D9D;
    cursor: pointer;
  }
  .btn-group-toggle [class*=btn-outline-]:not(:last-child) {
    border-right-width: 1px !important;
  }
  .inlineElement {
    display: flex;
  }
  .step-class {
    color: #104D9D;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
  }
  .dropzone-custom-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .dropzone-custom-title {
    margin-top: 0;
    color: #104D9D;
  }
  .subtitle {
    color: #333333;
  }
  .bookingCounter {
    border-radius: 50%;
    width: 5px;
    height: 5px;
    padding: 2px;

    background: #fff;
    border: 1px solid #666;
    color: #666;
    text-align: center;

    font-size: 10px;
  }

  .vs__dropdown-option--disabled {
    background: #ededed;
    color: #000000;
  }
</style>
